/**
 * Get the deployed url for the current environment.
 */
export function getDeployedUrl() {
  // deployed url - includes protocol
  if (process.env.NEXT_PUBLIC_BASE_URL) {
    return `${process.env.NEXT_PUBLIC_BASE_URL}`;
  }

  // reference for preview environments on vercel
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

/**
 * Get the download link for a given document sharing id.
 * @param id The document sharing id
 */
export function getDocumentSharingDownloadLink(documentSharingId: string) {
  return `${getDeployedUrl()}/api/links/${documentSharingId}`;
}

/**
 * Safely gets the current environment using the URL
 */
export function getCurrentEnvironment() {
  // Safely get the subdomain name
  const subdomainName = window?.location?.hostname?.split('.')?.[0];

  // Set the text based on the subdomain
  switch (subdomainName) {
    case 'localhost':
      return 'localhost';

    case 'staging':
      return 'staging';

    default:
      return 'production';
  }
}
