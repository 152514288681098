import { Flex } from '@chakra-ui/react';
import { HeaderComponent } from './components/HeaderComponent/HeaderComponent';
import { NoRenderer } from './components/NoRenderer/NoRenderer';
import { GoogleRenderer } from './renderers/GoogleRenderer';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

interface Props {
  file: string;
  type: string;
  onClose?: () => void;
}

const View = ({ file, type, onClose }: Props) => {
  return (
    <DocViewer
      documents={[
        {
          uri: file,
          fileType: type,
        },
      ]}
      config={{
        header: {
          overrideComponent: (state) => <HeaderComponent headerState={state} />,
        },
        noRenderer: {
          overrideComponent: (state) => (
            <NoRenderer document={state.document} onClose={onClose} />
          ),
        },
      }}
      pluginRenderers={[...DocViewerRenderers, GoogleRenderer]}
    />
  );
};

export const DocumentViewer = ({ file, type, onClose }: Props) => {
  return (
    <Flex
      flexDir={'column'}
      h={'100%'}
      w={'100%'}
      border={'1px solid rgba(0, 0, 0, 0.3)'}
    >
      <View file={file} type={type} onClose={onClose} />
    </Flex>
  );
};
